import React from 'react'
import mainContentPrizes from '../assets/img/creative-puding-nagrade.jpg'

const MainContent = () => {

  return (
    <div>
      <article className="mainContent pb-0 bg-white">
        <div className="px-2 lg:px-12">
          <h1 className="font-bold text-2xl sm:text-4xl lg:text-5xl mb-8 lg:mb-10">Awaken your creativity</h1>
          <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-8 lg:mb-10">Sudjelujte u programu lojalnosti “Creative puding” u kojem imate priliku osvojiti vrijedne nagrade!</h2>
          <p>Probudite svoju kreativnost uz Dr. Oetker jer možete osvojiti vrijedne nagrade.</p>
          <br />
          <p>Program lojalnosti traje od 1.11 – 30.11.2024. godine, a imate priliku osvojiti:</p>
          <p>7x BOSCH MOM uređaj, 50x poklon paket Dr.Oetker proizvoda</p>
          <br />
          <div className="max-w-[75ch] m-auto">
          <p><strong>Kako sudjelovati?</strong></p>
          <p>Slijedite par jednostavnih koraka jer nagrada može biti vaša!</p>
          <p>1. Kupite dva različita Creative pudinga na istom računu u bilo kojoj Bingo prodavnici na području Bosne i Hercegovine.</p>
          <p>2. Priložite fotografiju računa i popunite polja obrasca za prijavu koji se nalazi u nastavku.</p>
          <p>3. Odgovorite na kreativno pitanje. </p>
          <br />
          <p>Sretno!</p>
          </div>
          <br />
          <br />
          <div className="flex justify-center flex-column pt-5 py-14 w-full">
            <div className="w-full">
              <h3 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-6 lg:mb-6">Dobitnici</h3>
              <p className='font-bold'>BOSCH MUM 5</p>
              <p>Enisa Maksumić, Mostar</p>
              <p>Nejra Saračević, Sarajevo</p>
              <p>Radiša Lukić, Bijeljina</p>
              <p>Nafira Omerdić, Lukavac</p>
              <p>Josip Mravak, Sarajevo</p>
              <p>Munira Mujkić, Zenica</p>
              <p>Sanja Maksimović, Banja Luka</p>
              <br />
              <p className='font-bold'>DR. OETKER POKLON PAKET</p>
              <div className="flex flex-wrap justify-between">
                <div className="flex-1 min-w-full md:min-w-[30%] px-0 md:px-2">
                  <p>Miljan Miljanić, Zvornik</p>
                  <p>Šemsudina Fatkić, Bihać</p>
                  <p>Emina Fejzić, Žepče</p>
                  <p>Adelisa Bešić, Srebrenik</p>
                  <p>Sanela Kurtović, Sarajevo</p>
                  <p>Mirsada Pašić, Maglaj</p>
                  <p>Indira Brđanović, Gračanica</p>
                  <p>Sadeta Viteškić, Ilidža</p>
                  <p>Danijela Raus, Prijedor</p>
                  <p>Amna Trako, Zenica</p>
                  <p>Meliha Džafić, Visoko</p>
                  <p>Aleksandar Starcevic, Prijedor</p>
                  <p>Emina Kamber Omerović, Konjic</p>
                  <p>Muaz Aščić, Živinice</p>
                  <p>Aleksandra Latinovic, Banja Luka</p>
                  <p>Almedina Borović, Foča FBiH</p>
                  <p>Midhat Viteškić, Ilidža</p>
                </div>
                <div className="flex-1 min-w-full md:min-w-[30%] px-0 md:px-2">
                  <p>Marija Brekalo, Široki Brijeg</p>
                  <p>Olivera Nikolić, Bijeljina</p>
                  <p>Jovana Petreš, Laktaši</p>
                  <p>Anela Hiseni, Sarajevo</p>
                  <p>Sabina Pidgorcevic, Živinice</p>
                  <p>Velida Halilovic, Zenica</p>
                  <p>Dajana Zelenika, Mostar</p>
                  <p>Lamija Lugavić, Banovići</p>
                  <p>Aldina Kahriman Sehic, Sarajevo</p>
                  <p>Draženka Kraljić, Mostar</p>
                  <p>Velida Halilovic, Zenica</p>
                  <p>Emina Hrustić, Tuzla</p>
                  <p>Danka Vukojević, Prijedor</p>
                  <p>Almira Halilovic, Zavidovici</p>
                  <p>Ružica Šimunović, Mostar</p>
                  <p>Danijela Malinovic, Vitez</p>
                  <p>Rasema Selesković, Tuzla</p>
                </div>
                <div className="flex-1 min-w-full md:min-w-[30%] px-0 md:px-2">
                  <p>Muvedeta Omerovic, Hadzici</p>
                  <p>Arafat Bungurać, Donji Vakuf</p>
                  <p>Danijela Perić, Mostar</p>
                  <p>Jovo Malešević, Koz Dubica</p>
                  <p>Mehmed Avdagić, Sarajevo</p>
                  <p>Nejra Šegalo, Sarajevo</p>
                  <p>Zerina Ljuca, Sarajevo</p>
                  <p>Zekira Bajramović, Cazin</p>
                  <p>Milena Lukić, Bijeljina</p>
                  <p>Nenad Mičić, Banja Luka</p>
                  <p>Dijana Predragović, Laktaši</p>
                  <p>Amela Manigoda, Jablanica</p>
                  <p>Violeta Marković, Zvornik</p>
                  <p>Snjezana Petrovic, Prijedor</p>
                  <p>Alma Hrnjić, Živinice</p>
                  <p>Ilma Bliznović, Kakanj</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className="flex justify-center">
        <img className="w-full" src={mainContentPrizes} alt="game prizes" />
      </div>
      
    </div>
  )

}

export default MainContent